@keyframes pop {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(var(--scale));
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Wrapper {
    display: flex;
    box-sizing: border-box;
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    transform-origin: 0 0;
    touch-action: manipulation;

    &.fadeIn {
        animation: fadeIn 500ms ease;
    }

    &.dragOverlay {
        --scale: 1.05;
        z-index: 999;
    }
}

.Item {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    outline: none;
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;
    margin-bottom: 0.25rem;
    -webkit-tap-highlight-color: transparent;

    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

    &:focus-visible {
    }

    &:not(.withHandle) {
        touch-action: manipulation;
        cursor: grab;
    }

    &.dragging:not(.dragOverlay) {
        opacity: var(--dragging-opacity, 0.5);
        z-index: 0;

        &:focus {
        }
    }

    &.disabled {
        &:focus {
        }
        cursor: not-allowed;
    }

    &.dragOverlay {
        cursor: inherit;
        animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        transform: scale(var(--scale));
        opacity: 1;
    }

    &.color:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 100%;
        width: 3px;
        display: block;
    }
}

.Remove,
.Resize,
.Move {
    visibility: hidden;
}

.Move {
    cursor: grab !important;
}

.Actions {
    display: flex;
    align-self: flex-start;
    margin-top: -12px;
    margin-left: auto;
    margin-bottom: -15px;
    margin-right: -10px;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .Handle {
        cursor: default !important;
    }

    &:hover {
        .Remove,
        .Resize,
        .Move {
            visibility: visible;
        }
    }
}
