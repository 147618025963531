.GridContainer_Fixed {
    display: grid;
    grid-template-columns: repeat(var(--col-count), 1fr);
    grid-gap: 0.25rem;
    width: 100%;

    @media (max-width: 850px) {
        grid-template-columns: repeat(calc(var(--col-count) - 1), 1fr);
    }

    @media (max-width: 650px) {
        grid-template-columns: repeat(calc(var(--col-count) - 2), 1fr);
    }
}

.GridContainer_Fluid {
    display: flex;
    flex-wrap: wrap;
}
